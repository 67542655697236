'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Track the visitibity of the wrapped components
 *
 * <br>
 * [![codecov](https://codecov.io/gh/AvraamMavridis/react-intersection-visible/branch/master/graph/badge.svg)](https://codecov.io/gh/AvraamMavridis/react-intersection-visible) [![Build Status](https://travis-ci.org/AvraamMavridis/react-intersection-visible.svg?branch=master)](https://travis-ci.org/AvraamMavridis/react-intersection-visible) [![Greenkeeper badge](https://badges.greenkeeper.io/AvraamMavridis/react-intersection-visible.svg)](https://greenkeeper.io/)
 * <br>
 *
 * @export
 * @class IntersectionVisible
 * @extends {Component}
 */
var IntersectionVisible = function (_Component) {
  _inherits(IntersectionVisible, _Component);

  function IntersectionVisible() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, IntersectionVisible);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = IntersectionVisible.__proto__ || Object.getPrototypeOf(IntersectionVisible)).call.apply(_ref, [this].concat(args))), _this), _this.handleObserverUpdate = function (entries) {
      var _this$props = _this.props,
          onIntersect = _this$props.onIntersect,
          onShow = _this$props.onShow,
          onHide = _this$props.onHide;
      var intersectionRect = entries[0].intersectionRect;
      var top = intersectionRect.top,
          left = intersectionRect.left,
          bottom = intersectionRect.bottom,
          right = intersectionRect.right;


      if ([top, bottom, left, right].some(Boolean) && onShow) {
        onShow(entries);
      } else if (onHide) {
        onHide(entries);
      }

      onIntersect(entries);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Handles the visibility changes
   *
   * @param {array} entries
   */


  _createClass(IntersectionVisible, [{
    key: 'startObserving',


    /**
     * Starts the observer
     */
    value: function startObserving() {
      this.observer.observe(this.node);
    }

    /**
     * Stops the observer
     */

  }, {
    key: 'stopObserving',
    value: function stopObserving() {
      this.observer.unobserve(this.node);
    }

    /**
     * Start the observer when the component is mounted
     */

  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var options = this.props.options;

      this.observer = new IntersectionObserver(this.handleObserverUpdate, options);

      if (this.props.active) {
        this.startObserving();
      }
    }

    /**
     * Update observer state on prop changes
     */

  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (this.props.active && !prevProps.active) {
        this.startObserving();
      }

      if (!this.props.active && prevProps.active) {
        this.stopObserving();
      }
    }

    /**
     * Stop the observer on unmounting
     */

  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.observer.disconnect();
    }

    /**
     * Render component
     *
     * @returns {JSX.Element}
     */

  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var className = this.props.className;

      return _react2.default.createElement(
        'div',
        { className: className, ref: function ref(node) {
            return _this2.node = node;
          } },
        this.props.children
      );
    }
  }]);

  return IntersectionVisible;
}(_react.Component);

IntersectionVisible.propTypes = {
  /** Enable/disable the component */
  active: _propTypes2.default.bool,

  /** The wrapped component */
  children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),

  /** Class passed to the wrapper */
  className: _propTypes2.default.string,

  /**
   * Gets called when the wrapped component is visible
   *
   * @param {IntersectionObserverEntry} entries - <a href="https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry">Read more...</a>
   */
  onHide: _propTypes2.default.func,

  /**
   * Gets called when wrapped component interesects
   *
   * @param {IntersectionObserverEntry} entries - <a href="https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry">Read more...</a>
   */
  onIntersect: _propTypes2.default.func.isRequired,

  /**
   * Gets called when the wrapped component is visible
   *
   * @param {IntersectionObserverEntry} entries - <a href="https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry">Read more...</a>
   */
  onShow: _propTypes2.default.func,

  /**
   * Options passed to configure the listener
  */
  options: _propTypes2.default.shape({
    /** The element that is used as the viewport for checking visiblity of the target. Must be the ancestor of the target. Defaults to the browser viewport if not specified or if null. */
    root: _propTypes2.default.node,

    /**
     * Margin around the root. Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left). The values can be percentages. This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections. Defaults to all zeros.
    */
    rootMargin: _propTypes2.default.string,
    /**
     * Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed. If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5. If you want the callback run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1]. The default is 0 (meaning as soon as even one pixel is visible, the callback will be run). A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
    */
    threshold: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.arrayOf(_propTypes2.default.number)])
  })
};
IntersectionVisible.defaultProps = {
  active: true,
  className: 'intersection-visible-wrapper',
  onHide: function onHide() {
    return null;
  },
  onShow: function onShow() {
    return null;
  },
  onIntersect: function onIntersect() {
    return null;
  }
};
exports.default = IntersectionVisible;