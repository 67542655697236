"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _actions = require("./actions");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Adds query to location.
 * Utilises the search prop of location to construct query.
 */
var injectQuery = function injectQuery(location) {
  if (location && location.query) {
    // Don't inject query if it already exists in history
    return location;
  }

  var searchQuery = location && location.search;

  if (typeof searchQuery !== 'string' || searchQuery.length === 0) {
    return _objectSpread({}, location, {
      query: {}
    });
  } // Ignore the `?` part of the search string e.g. ?username=codejockie


  var search = searchQuery.substring(1); // Split the query string on `&` e.g. ?username=codejockie&name=Kennedy

  var queries = search.split('&'); // Contruct query

  var query = queries.reduce(function (acc, currentQuery) {
    // Split on `=`, to get key and value
    var _currentQuery$split = currentQuery.split('='),
        _currentQuery$split2 = _slicedToArray(_currentQuery$split, 2),
        queryKey = _currentQuery$split2[0],
        queryValue = _currentQuery$split2[1];

    return _objectSpread({}, acc, _defineProperty({}, queryKey, queryValue));
  }, {});
  return _objectSpread({}, location, {
    query: query
  });
};

var createConnectRouter = function createConnectRouter(structure) {
  var fromJS = structure.fromJS,
      merge = structure.merge;

  var createRouterReducer = function createRouterReducer(history) {
    var initialRouterState = fromJS({
      location: injectQuery(history.location),
      action: history.action
    });
    /*
    * This reducer will update the state with the most recent location history
    * has transitioned to.
    */

    return function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialRouterState;

      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          type = _ref.type,
          payload = _ref.payload;

      if (type === _actions.LOCATION_CHANGE) {
        var location = payload.location,
            action = payload.action,
            isFirstRendering = payload.isFirstRendering; // Don't update the state ref for the first rendering
        // to prevent the double-rendering issue on initilization

        return isFirstRendering ? state : merge(state, {
          location: fromJS(injectQuery(location)),
          action: action
        });
      }

      return state;
    };
  };

  return createRouterReducer;
};

var _default = createConnectRouter;
exports["default"] = _default;